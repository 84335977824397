<template>
    <div ref="container" class="swiper-container">
        <div class="swiper-wrapper">
            <div v-for="i in slideCount" :key="i" class="swiper-slide">
                <slot :name="`slide-${i}`"></slot>
            </div>
        </div>

        <div ref="scroll" class="swiper-scrollbar"></div>
    </div>
</template>

<script setup>
import { Swiper } from "swiper";
import { Scrollbar, Mousewheel, FreeMode } from "swiper/modules";

import { ref, onMounted, defineProps } from "vue";

defineProps({
    slideCount: {
        type: Number,
        required: true,
    },
});

const container = ref();
const scroll = ref();
const swiperInstance = ref();

onMounted(() => {
    swiperInstance.value = new Swiper(container.value, {
        modules: [Scrollbar, Mousewheel, FreeMode],
        direction: "horizontal",
        loop: false,
        mousewheel: {
            enabled: true,
            forceToAxis: true,
        },
        freeMode: true,
        slidesPerView: "auto",
        spaceBetween: 30,
        scrollbar: {
            el: scroll.value,
            draggable: true,
        },
    });
});
</script>

<style scoped>
.swiper-scrollbar {
    height: 0.5rem;
    cursor: pointer;
}
</style>
