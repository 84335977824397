import "core-js/stable";
import intlTelInput from "intl-tel-input";
import "regenerator-runtime/runtime";
import "./styles/main.scss";
import "./modules";
import "./components";
import formToDataLayer from "./modules/formToDataLayer.js";

formToDataLayer();

// import Vue from "vue";

window.intlTelInput = intlTelInput;

import { Swiper } from "swiper";
import { Navigation } from "swiper/modules";

window.Swiper = Swiper;
window.SwiperNavigation = Navigation;

// eslint-disable-next-line import/no-namespace
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

window.intlTelInput = intlTelInput;

// if (process.env.VUE_APP_SENTRY_DSN) {
//   Sentry.init({
//     Vue,
//     dsn: process.env.VUE_APP_SENTRY_DSN,
//     environment: process.env.NODE_ENV,
//     autoSessionTracking: true,
//     integrations: [new Integrations.BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//     attachProps: true,
//     logErrors: true,
//     debug: false,
//     tracingOptions: {
//       trackComponents: true,
//     },
//     ignoreErrors: [/Object Not Found Matching Id/],
//   });
// }

(async () => {
    if (!("scrollBehavior" in document.documentElement.style)) {
        await import("scroll-behavior-polyfill");
    }
})();
