<template>
    <div
        class="navigation-mobile"
        :class="{
            'navigation-mobile--active': activeItem,
            ['navigation-mobile--' + modifier]: modifier,
        }"
    >
        <div class="navigation-mobile__bar">
            <div class="navigation-mobile__container">
                <slot
                    :active-item="activeItem"
                    :handle-active-item-click="handleActiveItemClick"
                    name="bar"
                ></slot>
                <div
                    class="navigation-mobile__menu-item navigation-mobile__menu-item--hamburger"
                >
                    <span
                        class="hamburger"
                        :class="{ 'hamburger--open': menuActive }"
                        @click="
                            handleActiveItemClick(
                                menuActive ? false : 'menu-items'
                            )
                        "
                    >
                        <div class="hamburger__bar hamburger__bar--1"></div>

                        <div class="hamburger__bar hamburger__bar--2"></div>

                        <div class="hamburger__bar hamburger__bar--3"></div>
                    </span>
                </div>
            </div>
        </div>

        <div class="navigation-mobile__menu">
            <div class="navigation-mobile__menu-container">
                <slot name="menu-items" :menu-active="menuActive"></slot>

                <slot name="menu-cta"></slot>

                <slot
                    :active-item="activeItem"
                    :handle-active-item-click="handleActiveItemClick"
                    name="menu-quick-links"
                ></slot>

                <slot
                    :active-item="activeItem"
                    :handle-active-item-click="handleActiveItemClick"
                    name="menu-language-switcher"
                ></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const activeItem = ref("");

defineProps({
    modifier: {
        type: String,
        default: "",
    },
});

// const showMenuItems = computed(() => activeItem.value === "menu-items");
const menuActive = computed(
    () => activeItem.value && activeItem.value !== "menu-search"
);

const handleActiveItemClick = (activeItemValue) => {
    activeItem.value = activeItemValue;
    store.commit("resetItems");
};
</script>
