<template>
    <details ref="accordion" class="c-accordion">
        <summary class="c-accordion__summary">
            <slot name="summary" />

            <div class="c-accordion__icon">
                <svg-component
                    name="chevron-down-2"
                    :class="[
                        'c-chevron',
                        {
                            'c-chevron--big': hasBigIcon,
                        },
                    ]"
                />
            </div>
        </summary>
        <div class="c-accordion__content">
            <slot name="content" />
        </div>
    </details>
</template>

<script setup>
import { ref, onMounted } from "vue";

const props = defineProps({
    hasBigIcon: {
        type: Boolean,
        default: false,
    },
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const accordion = ref(null);

onMounted(() => {
    if (props.isOpen) {
        accordion.value.open = true;
    }
});
</script>
