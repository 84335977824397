class FormToDataLayer {
    constructor(form, { title, category, language }) {
        this.form = form;
        this.title = title || "title-unknown";
        this.category = category || "category-unknown";
        this.language = language || "language-unknown";
    }

    init() {
        this.fields = this.buildFormFields();
        this.addFormSubmitHandler();

        this._pushData({
            event: "vacancyDetail",
            vacancy: this.title,
            vacancyCategory: this.category,
            language: this.language,
        });
    }

    buildFormFields() {
        const formFields = {};
        const formFieldsArray = Array.from(this.form.elements);

        formFieldsArray.forEach((field) => {
            if (field.name) {
                formFields[field.name] = field.value;
                this.addFieldChangeHandler(field);
            }
        });

        return formFields;
    }

    addFormSubmitHandler() {
        this.form.addEventListener("submit", this.handleSubmit.bind(this));
    }

    // on submit , send data to dataLayer and submit form
    handleSubmit(event) {
        event.preventDefault();

        // submit form if form validates
        if (this.form.checkValidity()) {
            this._pushData({
                event: "SubmitApplication",
                vacancy: this.title,
                vacancyCategory: this.category,
                location:
                    this.fields["fields[kiesJeLocatie]"] || "location-unknown",
            });
        }
    }

    addFieldChangeHandler(field) {
        field.addEventListener("change", this.handleFieldChange.bind(this));
    }

    handleFieldChange(event) {
        const { name, value } = event.target;

        if (
            name &&
            (value !== this.fields[name] || event.target.type === "checkbox")
        ) {
            this.fields[event.target.name] = event.target.value;

            this._pushData({
                event: "dataEntry",
                vacancy: this.title,
                vacancyCategory: this.category,
                field: name,
            });
        }
    }
}

FormToDataLayer.prototype._pushData = function (data) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
};

const formToDataLayer = () => {
    const init = () => {
        const formWrappers = document.querySelectorAll(".form-to-datalayer");
        // get data-category attribute from form wrapper
        formWrappers.forEach((formWrapper) => {
            const language = document.documentElement.lang;
            const title = formWrapper.getAttribute("data-vacancy-title");
            const category = formWrapper.getAttribute("data-vacancy-category");
            const form = formWrapper.querySelector("form");

            if (form) {
                const formToDataLayer = new FormToDataLayer(form, {
                    title,
                    category,
                    language,
                });
                formToDataLayer.init();
            }
        });
    };

    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", init);
    } else {
        init();
    }
};

export default formToDataLayer;
