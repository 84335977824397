import ElementApiClient from "./ElementApiClient";

class ElementApiAgendaClient extends ElementApiClient {
    get(endpoint = null) {
        endpoint = endpoint || `/${this.language}/api/agenda`;

        return this.send("get", endpoint, {})
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                return false;
            });
    }
}

export default ElementApiAgendaClient;
