<template>
    <div class="c-switcher-links">
        <div
            class="c-switcher-links__active-item"
            @click="handleToggleDropdown"
        >
            <slot name="active-item" />
        </div>
        <div
            class="c-switcher-links__items-list"
            :class="{ 'c-switcher-links__items-list--open': isActive }"
        >
            <div
                v-for="item in items"
                :key="item.title"
                :class="[
                    'c-switcher-links__item',
                    `c-switcher-links__item--${item.languageCode}`,
                    {
                        'c-switcher-links__item--active': item.current,
                    },
                ]"
            >
                <a class="c-switcher-links__link" :href="item.url">
                    <span class="c-switcher-links__icon"></span>
                    {{ item.label }}
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
defineProps({
    items: {
        type: Array,
        default: () => [],
    },
});
const isActive = ref(false);

const handleToggleDropdown = () => {
    isActive.value = !isActive.value;
};
</script>
