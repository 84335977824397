window.addEventListener("load", () => {
    const searchToggle = document.getElementById("searchToggle");

    if (!searchToggle) {
        return;
    }

    searchToggle.addEventListener("click", (e) => {
        if (e.target.nodeName === "IMG" || e.path[0].id === "searchToggle") {
            searchToggle.classList.toggle("is-active--mobile");
        }
    });
});
