// For now this is good enough.
// When we need more mixins it should be divided into modules and exported accordingly.
const userDeviceHasHover = () => {
    return !window.matchMedia("(any-hover: none)").matches;
};

const mainNavigationHeight = () => {
    const navigation = document.querySelector(".navigation-mobile");

    if (!navigation) {
        return 0;
    }

    return navigation.offsetHeight;
};

export { userDeviceHasHover, mainNavigationHeight };
