/* eslint-disable */
import { ref, onMounted, onBeforeUnmount } from "vue";

export const useNavigationScrollController = (offset) => {
    const scrollDirection = ref(0);
    const hasScrolled = ref(false);
    let scrollTimeout;
    const scrollTop = ref(0);

    let lastScrollTop = 0;
    function onScroll() {
        hasScrolled.value = window.scrollY > offset;

        scrollTop.value = window.scrollY;
        const st = window.scrollY || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
            scrollDirection.value = 1;
        } else if (st < lastScrollTop) {
            scrollDirection.value = -1;
        }
        lastScrollTop = st <= 0 ? 0 : st;
    }

    onMounted(() => {
        window.addEventListener("scroll", onScroll);
    });

    onBeforeUnmount(() => {
        window.removeEventListener("scroll", onScroll);
    });

    return { hasScrolled, scrollDirection, scrollTop };
};
