<template>
    <div>
        <div v-if="show">
            <slot name="content" :hide-content="hideContent" />
        </div>
        <div v-else>
            <slot
                name="clicked"
                :show-content="showContent"
                :data-layer-push="dataLayerPush"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
    dataLayerEvent: {
        type: String,
        default: "",
    },
});

const show = ref(false);

const pushDataLayer = () => {
    if (!window) {
        return;
    }
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push({
        event: props.dataLayerEvent,
    });
};

const showContent = () => {
    show.value = true;
    if (props.dataLayerEvent !== null || props.dataLayerEvent !== "") {
        pushDataLayer();
    }
};

const hideContent = () => {
    show.value = false;
};

const dataLayerPush = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: "gtm.video" });
};
</script>
