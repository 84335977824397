<template>
    <div>
        <slot
            name="menu"
            :active-tab="activeTab"
            :handle-click="handleClick"
            :events="{ handleClick }"
        ></slot>
        <slot :name="tabPanelSlotName"></slot>
        <slot name="tabs" :active-item="activeTab"></slot>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { kebabCase } from "lodash";

const props = defineProps({
    initialTab: {
        type: String,
        required: false,
        default: "",
    },
});

const activeTab = ref("");
const tabPanelSlotName = computed(
    () => `tab-panel-${kebabCase(activeTab.value)}`
);

onMounted(() => {
    activeTab.value = props.initialTab;
});

const switchTab = (slug) => {
    activeTab.value = slug;
};

const handleClick = (event) => {
    switchTab(event.target.dataset.slug);
};
</script>
