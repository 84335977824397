<template>
    <li class="accordion__item" :class="{ 'accordion__item--active': visible }">
        <div class="accordion__trigger" @click="handleClick">
            <slot name="accordion-trigger"></slot>
        </div>

        <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end"
        >
            <div v-show="visible" class="accordion__content">
                <slot name="accordion-content"></slot>
            </div>
        </transition>
    </li>
</template>

<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import qs from "qs";

const store = useStore();
const { isAccordionItemActive, getAccordionByName } = store.getters;

const props = defineProps({
    slug: {
        required: true,
        type: String,
    },
    level: {
        required: false,
        type: Number,
        default: 0,
    },
    accordionName: {
        required: true,
        type: String,
    },
});

const visible = computed(() =>
    isAccordionItemActive(props.accordionName, props.level, props.slug)
);

watch(visible, () => {
    const queryString = qs.stringify({
        accordion: {
            [props.accordionName]: getAccordionByName(props.accordionName),
        },
    });

    history.replaceState(
        null,
        null,
        `${window.location.pathname}?${queryString}`
    );
});

const handleClick = () => {
    store.dispatch("setActiveItem", {
        accordionName: props.accordionName,
        level: props.level,
        slug: visible.value ? "" : props.slug,
    });
};

const start = (el) => {
    el.style.height = `${el.scrollHeight}px`;
};

const end = (el) => {
    el.style.height = "";
};
</script>
