<template>
    <ul class="faq">
        <li
            v-for="category in categories"
            :key="category.id"
            class="faq__category"
        >
            <h3 :id="category.id">{{ category.title }}</h3>
            <ul class="faq__items">
                <li
                    v-for="(question, index) in category.questions"
                    :key="index"
                    class="faq__item"
                >
                    <a class="faq__question" @click.prevent="toggleQuestion">{{
                        question.question
                    }}</a>
                    <div class="faq__answer">
                        <!-- eslint-disable vue/no-v-html -->
                        <div class="wysiwyg" v-html="question.answer" />
                        <!-- esline-enable -->
                        <div v-if="question.button" class="faq__button">
                            <a
                                :href="question.button.url"
                                class="text-button__arrow"
                                >{{ question.button.text }}</a
                            >
                        </div>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script setup>
defineProps({
    categories: {
        required: true,
        type: Array,
    },
});

const toggleQuestion = (event) => {
    event.target.classList.toggle("faq__question--active");
};
</script>
