<template>
    <ul class="updates">
        <li v-for="item in items" :key="item.slug">
            <content-list-item-small
                v-if="
                    item.type &&
                    item.type !== 'newsDetail' &&
                    item.type !== 'agendaDetail' &&
                    item.type !== 'campaign'
                "
                :item="mapItem(item)"
                :labels="labels"
            />
            <content-list-item-default
                v-else
                :item="mapItem(item)"
                :placeholder-image="placeholderImage"
                :labels="labels"
            />
        </li>
        <li v-if="items && items.length === 0" class="updates__no-results">
            <slot></slot>
        </li>
    </ul>
</template>

<script setup>
defineProps({
    placeholderImage: {
        type: String,
        required: true,
    },
    items: {
        required: true,
        type: Array,
    },
    labels: {
        required: true,
        type: Object,
    },
});

const mapItem = (item) => {
    // Using Craft ElementAPI
    if (!item.objectID) {
        return item;
    }

    // Using Algolia index
    return {
        ...item,
        featuredImage: { url: item.featuredImageUri },
    };
};
</script>
