<template>
    <ul
        class="navigation-list navigation-list--bordered-bottom navigation-list--sticky"
    >
        <li
            v-for="tab in tabs"
            :key="tab.slug"
            class="navigation-list__item navigation-list__item--more-mt"
            :class="{
                'navigation-list__item--active': isAccordionItemActive(
                    accordionName,
                    0,
                    tab.slug
                ),
            }"
            @click="handleClick(tab.slug)"
        >
            <h4>
                {{ tab.title }}
            </h4>
        </li>
    </ul>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
    tabs: {
        required: true,
        type: [Object, Array],
    },
    accordionName: {
        required: true,
        type: String,
    },
});

const store = useStore();

const isAccordionItemActive = computed(
    () => store.getters.isAccordionItemActive
);
const setActiveItem = store.dispatch.bind(store, "setActiveItem");

const handleClick = (slug) => {
    setActiveItem({
        accordionName: props.accordionName,
        level: 0,
        slug,
    });
};
</script>
