const helpers = (() => {
    const handleDataLayerPush = (event) => {
        event.preventDefault();

        if (event?.currentTarget?.dataset?.analyticsEvent) {
            window.dataLayer.push({
                event: event.currentTarget.dataset?.analyticsEvent,
                login_item: event.currentTarget.dataset?.loginItem,
                login_type: event.currentTarget.dataset?.loginType,
            });
        }

        if (event?.currentTarget?.target === "_blank") {
            window.open(event.currentTarget.href, "_blank").focus();
            return;
        }

        window.location.href = event?.currentTarget?.href;
    };

    return { handleDataLayerPush };
})();

window.helpers = helpers;
