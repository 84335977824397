<template>
    <div v-if="hasNextLink" class="has-text-centered">
        <a
            :href="pagination.links.next"
            class="button button--load-more is-transparent"
            rel="next"
            @click.prevent="handleClick"
            >{{ text }}</a
        >
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    pagination: {
        type: Object,
        required: true,
    },
    text: {
        type: String,
        default: "Show more",
    },
});

const emit = defineEmits(["load-more"]);

const hasNextLink = computed(() => {
    return props.pagination.current_page < props.pagination.total_pages;
});

const handleClick = () => {
    emit("load-more", props.pagination.links.next);
};
</script>
