import { createStore } from "vuex";

const mainNavigation = {
    state: () => ({
        activeItems: {
            0: "",
            1: "",
            2: "",
            3: "",
        },
        lastActiveItem: "",
    }),
    mutations: {
        changeItem: (state, { level, slug }) => {
            Object.keys(state.activeItems).forEach((item, index) => {
                if (index === level) {
                    state.activeItems[index] = slug;
                }
                if (index > level) {
                    state.activeItems[index] = "";
                }
            });

            state.lastActiveItem = slug;
        },
        resetItems(state) {
            state.activeItems = {
                0: "",
                1: "",
                2: "",
                3: "",
            };
        },
    },
};

const accordionState = {
    state: () => ({
        activeItems: {},
    }),
    getters: {
        isAccordionActive: (state) => (accordionName) => {
            if (!state.activeItems?.[accordionName]) {
                return false;
            }
            return Object.values(state.activeItems[accordionName]).some(
                (value) => value
            );
        },
        isAccordionItemActive: (state) => (accordionName, level, slug) => {
            return state.activeItems?.[accordionName]?.[level] === slug;
        },
        getAccordionByName: (state) => (accordionName) => {
            return state.activeItems?.[accordionName];
        },
    },
    mutations: {
        initAccordion: (state, { accordionName, initState }) => {
            state.activeItems = {
                ...state.activeItems,
                [accordionName]: initState,
            };
        },
        setActiveItem: (state, { accordionName, level, slug }) => {
            Object.keys(state.activeItems[accordionName]).forEach(
                (item, index) => {
                    if (index === level) {
                        state.activeItems[accordionName][level] = slug;
                    }
                    if (index > level) {
                        state.activeItems[accordionName][index] = "";
                    }
                }
            );
        },
    },
    actions: {
        setActiveItem: ({ commit }, payload) => {
            commit("setActiveItem", payload);
        },
    },
};
const searchState = {
    state: () => ({
        searchIsActive: false, // Boolean state for search
    }),
    getters: {
        isSearchActive: (state) => {
            return state.searchIsActive;
        },
    },
    mutations: {
        setSearchIsActive: (state, isActive) => {
            state.searchIsActive = isActive;
        },
    },
    actions: {
        setSearchIsActive: ({ commit }, isActive) => {
            commit("setSearchIsActive", isActive);
        },
    },
};

export default createStore({
    modules: {
        mainNavigation,
        accordionState,
        searchState,
    },
});
