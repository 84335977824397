<template>
    <div class="image-gallery">
        <div class="image-gallery__main">
            <button class="u-basic-reset u-clickable">
                <div class="image-container">
                    <span
                        v-if="mainImage.type === 'embed'"
                        class="image-container__icon-play"
                    ></span>

                    <img
                        v-if="images && mainImage"
                        :src="mainImage.imageUrl"
                        :alt="`Primaire afbeelding: ${mainImage.caption}`"
                        class="image-gallery__image"
                        @click="openModal(mainImage)"
                    />
                </div>
            </button>

            <div
                v-if="mainImage.caption"
                class="image-gallery__primary-caption"
            >
                {{ mainImage.caption }}
            </div>

            <ul v-if="secondaryImages" class="image-gallery__secondary-images">
                <li
                    v-for="(item, index) in secondaryImages"
                    :key="`secondary-image${index}`"
                    class="image-gallery__secondary-images-item"
                >
                    <button class="u-basic-reset u-clickable u-width-100">
                        <div class="image-container">
                            <span
                                v-if="
                                    item.type === 'embed' && !isLastItem(index)
                                "
                                class="image-container__icon-play image-container__icon-play--small"
                            ></span>

                            <img
                                :src="item.thumbnailUrl"
                                :alt="`Secondaire afbeelding: ${item.caption}`"
                                class="image-gallery__image"
                                @click="openModal(item)"
                            />

                            <div
                                v-if="numberExtraImages && isLastItem(index)"
                                class="image-container__overlay"
                                @click="openModal(item)"
                            >
                                <span class="image-container__number"
                                    >+{{ numberExtraImages }}</span
                                >
                            </div>
                        </div>
                    </button>
                </li>
            </ul>
        </div>

        <div
            ref="modal"
            class="image-gallery__modal"
            @click="handleOverlayClick"
        >
            <button class="image-gallery__close">X</button>

            <div class="image-gallery__modal-inner">
                <div class="image-gallery__details">
                    <button
                        class="image-gallery__details-button image-gallery__details-button--previous"
                        @click="slide('previous')"
                    ></button>

                    <img
                        v-if="selectedImage && selectedImage.type == 'image'"
                        :key="selectedImage.id"
                        :src="selectedImage.imageModalUrl"
                        :alt="`Secondaire afbeelding: ${selectedImage.caption}`"
                        class="image-gallery__modal-image"
                    />

                    <div
                        v-if="selectedImage && selectedImage.type == 'embed'"
                        class="image-gallery__player"
                    >
                        <div class="image-container">
                            <iframe
                                ref="embedPlayer"
                                :src="selectedImage.embedUrl"
                                frameborder="0"
                            ></iframe>
                        </div>
                    </div>

                    <button
                        class="image-gallery__details-button image-gallery__details-button--next"
                        @click="slide('next')"
                    ></button>
                </div>

                <div
                    v-if="images && selectedImage"
                    class="image-gallery__navigation"
                >
                    <span class="image-gallery__navigation-title">{{
                        selectedImage.caption
                    }}</span>
                    <ul class="image-gallery__indicator">
                        <li
                            v-for="(item, index) in images"
                            :key="`indicator-${index}`"
                            class="image-gallery__indicator-item"
                            :class="{
                                'image-gallery__indicator-item--active':
                                    item.id == selectedImage.id,
                            }"
                            @click="selectImage(item)"
                        ></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
    images: {
        required: true,
        type: Array,
    },
});

const selectedImage = ref(null);

const modal = ref(null);
const embedPlayer = ref(null);

const mainImage = computed(() => {
    return props.images ? props.images[0] : null;
});

const secondaryImages = computed(() => {
    return props.images ? props.images.slice(1, 5) : null;
});

const numberExtraImages = computed(() => {
    return props.images.length - secondaryImages.value.length - 1;
});

const openModal = (item) => {
    selectedImage.value = item;
    modal.value.classList.toggle("image-gallery__modal--open");
};

const isLastItem = (index) => {
    return index === secondaryImages.value.length - 1;
};

const closeModal = () => {
    modal.value.classList.remove("image-gallery__modal--open");
};

const stopEmbedPlayer = () => {
    embedPlayer.value.src = "";
    embedPlayer.value.src = selectedImage.value.embedUrl;
};

const selectImage = (item) => {
    selectedImage.value = item;
};

const slide = (direction) => {
    const index = props.images.findIndex((item) => {
        return item.id === selectedImage.value.id;
    });
    if (direction === "previous") {
        index === 0
            ? (selectedImage.value = props.images[props.images.length - 1])
            : (selectedImage.value = props.images[index - 1]);
    }
    if (direction === "next") {
        index >= props.images.length - 1
            ? (selectedImage.value = props.images[0])
            : (selectedImage.value = props.images[index + 1]);
    }
};

const handleOverlayClick = (event) => {
    const closeElements = [
        "image-gallery__details",
        "image-gallery__modal",
        "image-gallery__close",
    ];

    const shouldClose = closeElements.some((element) => {
        return event.target.classList.contains(element);
    });

    if (!shouldClose) return;

    closeModal();

    if (selectedImage.value.type !== "embed") return;

    stopEmbedPlayer();
};
</script>
