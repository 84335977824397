<!-- eslint-disable vue/no-multiple-template-root -->

<template>
    <slot :has-scrolled="hasScrolled" :scroll-direction="scrollDirection" />
</template>

<script setup>
// eslint-disable-next-lin
import { useNavigationScrollController } from "../../hooks/useNavigationScrollController";

const topNavigationHeight = 36;

const { hasScrolled, scrollDirection } =
    useNavigationScrollController(topNavigationHeight);
</script>
