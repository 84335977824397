<template>
    <div class="c-check-input">
        <slot>
            <label :for="kebabCaseValue" class="c-check-input__label">
                <input
                    :id="kebabCaseValue"
                    type="checkbox"
                    :checked="isChecked"
                    class="c-check-input__input"
                />
                <span class="c-check-input__box" />
                {{ label }}
            </label>
        </slot>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { kebabCase } from "lodash";

const props = defineProps({
    isChecked: {
        type: Boolean,
        default: false,
    },
    value: {
        type: String,
        default: "",
    },
    label: {
        type: String,
        default: "",
    },
});

const kebabCaseValue = computed(() => kebabCase(props.value));
</script>

<style lang="scss">
.c-check-input {
    &__input {
        display: none;
    }

    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
        align-items: center;
        text-decoration: none;

        @include tablet-landscape {
            font-size: 14px;
        }
    }

    &__link {
        text-decoration: none;
    }

    &__box {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 10%;
        border: $border-standard-2023;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-right: 8px;
    }

    &__input:checked + &__box {
        border-color: $black;

        &::after {
            content: "";
            height: 16px;
            width: 16px;
            background-color: $green;
            border-radius: 10%;
        }
    }
}
</style>
