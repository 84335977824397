<template>
    <button class="c-close-button">
        <slot />
        <svg-component name="close" />
    </button>
</template>

<style lang="scss">
.c-close-button {
    @include basic-reset;
    display: flex;
    align-items: center;
    font-family: $title-font-stack;
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    column-gap: 8px;
    font-weight: 200;
    align-items: center;

    @include on-event {
        text-decoration: none;
    }

    svg {
        width: 10px;
        height: 10px;
    }
}
</style>
