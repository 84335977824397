<template>
    <ais-instant-search
        :search-client="searchClient"
        :index-name="searchIndex + '_query_suggestions'"
        :future="{ preserveSharedStateOnUnmount: false }"
    >
        <ais-autocomplete v-slot="{ currentRefinement, indices, refine }">
            <div
                class="searchbar"
                :class="{
                    'searchbar--active': isActive,
                    'searchbar--hide-mobile': hideMobile,
                }"
            >
                <button :class="buttonClass" @click="updateActiveState">
                    <slot name="icon" />

                    <template v-if="!isActive">
                        {{ labels.search }}
                    </template>
                </button>

                <input
                    ref="input"
                    v-model="query"
                    type="text"
                    name="search"
                    class="searchbar__input"
                    :class="{ 'searchbar__input--full-width': fullWidthInput }"
                    @input="() => debounceInput(refine, query)"
                    @keyup.enter="handleEnter"
                    @blur="updateActiveState"
                />

                <div
                    class="searchbar__content"
                    :class="{ 'searchbar__content--active': showContent }"
                >
                    <template
                        v-if="!(currentRefinement && indices[0].hits.length)"
                    >
                        <h6 class="u-margin-bottom-tiny">
                            {{ labels.popularTerms }}
                        </h6>

                        <ul>
                            <li
                                v-for="term in popularTerms"
                                :key="term.searchQuery"
                            >
                                <a
                                    class="link link--decorated"
                                    :href="createUrl(term.searchQuery)"
                                >
                                    {{ term.searchQuery }}
                                </a>
                            </li>
                        </ul>

                        <main-navigation-recent-searches
                            :search-base-url="searchBaseUrl"
                            class="u-margin-bottom-small"
                        >
                            {{ labels.history }}
                        </main-navigation-recent-searches>
                    </template>

                    <ul v-else>
                        <li v-for="hit in indices[0].hits" :key="hit.objectID">
                            <a
                                class="link link--decorated"
                                :href="createURL(hit.query)"
                            >
                                <ais-highlight
                                    attribute="query"
                                    :hit="hit"
                                    :class-names="{
                                        'ais-Highlight': 'highlight',
                                        'ais-Highlight-highlighted':
                                            'highlight__thin',
                                    }"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </ais-autocomplete>
    </ais-instant-search>
</template>

<script setup>
import { debounce } from "lodash";
import { searchClient } from "../../algoliaSearchClient";
import { ref } from "vue";

const props = defineProps({
    buttonClass: {
        type: [String, Array, Object],
        default: null,
    },
    searchIndex: {
        type: String,
        required: true,
    },
    searchBaseUrl: {
        type: String,
        required: true,
    },
    popularTerms: {
        type: Array,
        default: () => [],
    },
    labels: {
        type: Object,
        required: true,
    },
    fullWidthInput: {
        type: Boolean,
        default: false,
    },
    hideMobile: {
        type: Boolean,
        default: false,
    },
});

const input = ref();

const isActive = ref(false);
const showContent = ref(false);
const query = ref("");

function updateActiveState() {
    if (isActive.value) {
        setTimeout(() => (isActive.value = false), 200);
        showContent.value = false;
    } else {
        isActive.value = true;
        setTimeout(() => (showContent.value = true), 200);

        input.value?.focus();
    }

    showContent.value = false;
}

const debounceInput = debounce((refine, query) => {
    if (query.length <= 2) return;

    refine(query.value);
}, 500);

function createUrl(query) {
    return `${props.searchBaseUrl}?query=${encodeURIComponent(query)}`;
}

function handleEnter() {
    if (!query.value) return;

    window.location.href = createUrl(query.value);
}
</script>
