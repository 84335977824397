import axios from "axios";

class ElementApiClient {
    constructor(language) {
        this.baseUrl = process.env.VUE_APP_ELEMENT_API_BASE_URL;
        this.token = process.env.VUE_APP_ELEMENT_API_TOKEN;
        this.language = language;
        this.isLoading = false;

        this.init();
    }

    init() {
        this.defaultHeaders = {
            authorization: `Bearer ${this.token}`,
        };
    }

    get(endpoint, conf) {
        return this.send("get", endpoint, conf);
    }

    send(method, endpoint) {
        this.isLoading = true;

        const requestConf = {
            method,
            baseURL: this.baseUrl,
            url: endpoint,
            headers: {
                ...this.defaultHeaders,
            },
        };

        return new Promise((resolve, reject) => {
            axios(requestConf)
                .then((response) => {
                    this.isLoading = false;
                    if (response.status > 299) {
                        reject(response);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    this.isLoading = false;
                    reject(error);
                });
        });
    }
}

export default ElementApiClient;
