<template>
    <div>
        <div class="agenda-index__sidebar__year-list">
            <div class="pill-list">
                <div
                    v-for="(year, index) in yearList"
                    :key="`year-${index}`"
                    class="pill-list__item"
                >
                    <a
                        :href="year.url"
                        class="pill"
                        :class="{ 'is-active': filterYear === year.text }"
                    >
                        {{ year.text }}
                    </a>
                </div>
            </div>
        </div>
        <div class="agenda-index__sidebar__month-list">
            <div
                v-for="(month, key) in monthList"
                :key="`month-${key}`"
                class="agenda-index__sidebar__month-item"
            >
                <a
                    v-if="month.hasItems"
                    class="agenda-index__sidebar__month"
                    :href="month.url"
                    :class="{ 'is-active': selectedMonth === key }"
                    @click.prevent="handleMonthSelect(key)"
                >
                    {{ month.text }}
                </a>

                <div v-else class="agenda-index__sidebar__month is-disabled">
                    {{ month.text }}
                </div>
            </div>
        </div>

        <a
            v-if="slim"
            class="button is-transparent u-margin-top-small"
            :href="overviewUrl"
        >
            {{ labels.showEverything }}
        </a>
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    overviewUrl: {
        type: String,
        required: false,
        default: "",
    },
    slim: {
        default: false,
        type: Boolean,
    },
    yearList: {
        required: true,
        type: Array,
    },
    monthList: {
        required: true,
        type: Object,
    },
    filterYear: {
        required: true,
        type: Number,
    },
    filterMonth: {
        type: Number,
        default: null,
    },
    labels: {
        required: true,
        type: Object,
    },
});

const selectedMonth = computed(() => {
    if (!props.filterMonth) {
        return;
    }

    return `${props.filterYear}-${props.filterMonth
        .toString()
        .padStart(2, "0")}`;
});

const emit = defineEmits(["select-month"]);

const handleMonthSelect = (monthKey) => {
    const monthNr = Number(monthKey.split("-")[1]);
    emit("select-month", monthNr);
};
</script>
