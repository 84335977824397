<template>
    <div class="c-label">
        <strong>
            <slot name="label" />
        </strong>
        <slot name="value" />
    </div>
</template>

<style lang="scss" scoped>
.c-label {
    font-size: 14px;
    color: $black;

    strong {
        text-transform: uppercase;
        margin-right: 0.5rem;
    }
}
</style>
