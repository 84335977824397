import { createApp } from "vue";
import store from "./../store";
import InstantSearch from "vue-instantsearch/vue3/es";
import { ObserveVisibility } from "vue-observe-visibility";

// Create a Vue application instance
const app = createApp({
    mounted() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("loaded");
                    entry.target.classList.add("in-view");
                } else {
                    entry.target.classList.remove("in-view");
                }
            });
        });

        const elements = document.querySelectorAll("[data-observe]");
        elements.forEach((el) => observer.observe(el));
    },
});

app.directive("observe-visibility", {
    beforeMount: (el, binding, vnode) => {
        vnode.context = binding.instance;
        ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
});

// Automatically require and register components globally
const requireComponent = require.context("./", true, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    const componentName = fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "");

    // Register component globally
    app.component(componentName, componentConfig.default || componentConfig);
});

// Mount Vue application to the div with id="app"
app.use(InstantSearch);
app.use(store);
app.mount("#app");
