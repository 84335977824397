<template>
    <div>
        <slot :handle-click="handleClick" :open="open" name="trigger"></slot>

        <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end"
        >
            <div v-show="open">
                <slot :focus="focus" name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
    slug: {
        type: String,
        required: true,
    },
    level: {
        type: Number,
        required: true,
    },
});

const store = useStore();

const open = computed(
    () => store.state.mainNavigation.activeItems[props.level] === props.slug
);

const focus = computed(
    () => store.state.mainNavigation.lastActiveItem === props.slug
);

const handleClick = () => {
    store.commit("changeItem", {
        level: props.level,
        slug: open.value ? "" : props.slug,
    });
};

const start = (el) => {
    el.style.height = el.scrollHeight + "px";
};

const end = (el) => {
    el.style.height = "";
};
</script>
