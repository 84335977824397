const searchHistory = () => {
    const localStorageKey = "sh";
    let searchHistory = [];
    const maxHistoryLog = 5;

    const persist = () => {
        localStorage.setItem(localStorageKey, JSON.stringify(searchHistory));

        return searchHistory;
    };

    const add = ({ title, url, query }) => {
        title = title || query;
        if (!title.length) {
            return;
        }

        const existingSearch = searchHistory.findIndex(
            (search) => url === search.url
        );

        if (existingSearch !== -1) {
            searchHistory.splice(existingSearch, 1);
        }

        searchHistory.push({ title, url, query });

        if (searchHistory.length === maxHistoryLog + 1) {
            searchHistory.shift();
        }

        return persist();
    };

    const clear = () => {
        searchHistory = [];

        return persist();
    };

    const all = () => {
        return searchHistory;
    };

    if (!localStorage.getItem(localStorageKey)) {
        persist(searchHistory);
    } else {
        searchHistory = JSON.parse(localStorage.getItem(localStorageKey));
    }

    return {
        add,
        clear,
        all,
    };
};

export default searchHistory();
