<template>
    <div>
        <div class="agenda-index__content-list">
            <agenda-index-content-list-item
                v-for="listItem in listItems"
                :key="listItem.id"
                :list-item="listItem"
                :labels="labels"
                :placeholder-image="placeholderImage"
            />

            <load-more-btn
                v-if="pagination && !isLoading && !slim"
                :pagination="pagination"
                :text="labels.showMore"
                @load-more="handleLoadMoreAgendaItems"
            />

            <div v-if="slim" class="has-text-centered">
                <a class="button is-transparent" :href="overviewUrl">
                    {{ labels.showEverything }}
                </a>
            </div>
        </div>

        <template v-if="isLoading">
            <h3>{{ labels.loading }}</h3>
        </template>
    </div>
</template>

<script setup>
import { onMounted, ref, computed, watch, reactive } from "vue";
import ApiClient from "../services/ElementApi/ElementApiAgendaClient";

const props = defineProps({
    placeholderImage: {
        type: String,
        required: true,
    },
    overviewUrl: {
        type: String,
        required: false,
        default: "",
    },
    slim: {
        default: false,
        type: Boolean,
    },
    language: {
        required: true,
        type: String,
    },
    labels: {
        required: true,
        type: Object,
    },
    initialListItems: {
        type: Array,
        default: () => [],
    },
    initialPage: {
        type: Number,
        default: 1,
    },
    filterMonth: {
        type: Number,
        default: null,
    },
    filterYear: {
        type: Number,
        default: null,
    },
});

const apiClient = ref(new ApiClient(props.language));
const listItems = ref([]);
const pagination = ref({});
const page = ref(1);
const isLoading = ref(true);

const params = reactive({
    page: props.pagination ? props.pagination.current_page : null,
    year: props.filterYear,
    month: props.filterMonth,
});

const apiUrl = computed(() => {
    return props.language === "nl"
        ? `/api/agenda?year=${props.filterYear}&month=${props.filterMonth}`
        : `/${props.language}/api/agenda?year=${props.filterYear}&month=${props.filterMonth}`;
});

const queryString = computed(() => {
    if (!params.value) {
        return null;
    }
    return Object.keys(params)
        .filter(
            (k) =>
                (k === "page" && params.value[k] > 1) ||
                (k !== "page" && params.value[k])
        )
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
});

watch(
    () => props.filterMonth,
    () => {
        reset();
    }
);

watch(queryString, (newQueryString) => {
    history.replaceState(
        null,
        null,
        window.location.pathname + (newQueryString ? "?" + newQueryString : "")
    );
});

onMounted(() => {
    listItems.value = props.initialListItems;
    page.value = props.initialPage;
});

function reset() {
    listItems.value = [];
    loadAgendaItems(apiUrl.value);
}

async function loadAgendaItems(endpoint) {
    isLoading.value = true;
    const response = await apiClient.value.get(endpoint);

    if (!response) {
        // something was up
        console.error("could not fetch list");
        isLoading.value = false;
        return;
    }

    listItems.value = [...listItems.value, ...response.data];
    pagination.value = response.meta.pagination;
    isLoading.value = false;
}
async function handleLoadMoreAgendaItems(nextLink) {
    loadAgendaItems(nextLink);
}
</script>
