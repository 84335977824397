/**
 * @param {String} message
 * @param {Object} options
 * @return {String}
 */
const enrichMessage = (message, options) => {
    if (!message || !options || !options.params) return message;

    let string = message;

    for (const [key, value] of Object.entries(options.params)) {
        string = string.replaceAll(`{${key}}`, value);
    }

    return string;
};

/**
 * @param {Object} staticMessages - Object with all messages
 * @param {String} message - The message that needs to be translated
 * @param options
 * @return {String}
 */
const translateMessage = (staticMessages, message, options = null) => {
    if (!staticMessages || !(message in staticMessages)) {
        //If there isn't a translation, return original value and warn developer
        console.warn(`"${message}" hasn't been translated yet`);

        return enrichMessage(message, options);
    }

    const translation = staticMessages[message];
    if (!translation) {
        return enrichMessage(message, options);
    }
    return enrichMessage(translation, options);
};

/**
 * @param {String} message
 * @param {Object} options
 * @return {String} - Translated message
 */
const translate = (message, options = null) => {
    window.dataStatic = window.dataStatic || { translations: {} };
    const staticMessages = window.dataStatic.translations;
    return translateMessage(staticMessages, message, options);
};

export { translateMessage, translate };
