<template>
    <div ref="container" class="c-tabs">
        <ul class="c-tabs__list">
            <slot :set-element="setElement"></slot>
        </ul>
    </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
const element = ref();
const container = ref();

const setElement = (el) => {
    element.value = el;
};

watchEffect(() => {
    if (element.value) {
        const target =
            element.value.offsetLeft -
            container.value.offsetLeft -
            container.value.offsetWidth / 2 +
            element.value.offsetWidth / 2;
        container.value.scrollLeft = target;
    }
});
</script>
