<template>
    <div
        class="scrollable-content"
        :class="{
            'scrollable-content--centered': singleTab,
        }"
    >
        <nav
            ref="nav"
            class="scrollable-content__navigation scrollable-content__navigation--sticky"
            :class="{ 'scrollable-content__navigation--hidden': singleTab }"
        >
            <ul class="scrollable-content__navigation-list">
                <li
                    v-for="tab in tabs"
                    :key="tab.slug"
                    class="scrollable-content__navigation-item"
                    :class="{
                        'scrollable-content__navigation-item--active':
                            tab.slug == activeTab,
                    }"
                    @click="handleClick(tab.slug)"
                >
                    <div class="scrollable-content__navigation-title">
                        {{ tab.title }}
                    </div>
                </li>
            </ul>
        </nav>
        <div class="scrollable-content__content">
            <div ref="content" class="content-blocks">
                <slot :name="tabPanelSlotName"></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { userDeviceHasHover, mainNavigationHeight } from "./../mixins";

const props = defineProps({
    initialTab: {
        required: true,
        type: String,
    },
    tabs: {
        required: true,
        type: Array,
    },
});

const activeTab = ref(props.initialTab);

const nav = ref(null);
const content = ref(null);

const singleTab = computed(() => {
    return props.tabs.length <= 1;
});

const tabPanelSlotName = computed(() => {
    return `tab-panel-${activeTab.value}`;
});

const offset = computed(() => {
    return !userDeviceHasHover
        ? mainNavigationHeight + nav.value.offsetHeight
        : 12;
});

const switchTab = (slug) => {
    activeTab.value = slug;
};
const scrollToContentTop = () => {
    const y =
        content.value.getBoundingClientRect().top +
        window.scrollY -
        offset.value;
    window.scrollTo({ top: y, behavior: "smooth" });
};

const handleClick = (slug) => {
    switchTab(slug);
    scrollToContentTop();
};
</script>
