<template>
    <ul :class="['c-filtering', { 'c-filtering--is-root-level': isRootLevel }]">
        <li
            v-for="item in props.items"
            :key="item.value"
            class="c-filtering__item"
        >
            <check-input>
                <template #default>
                    <div>
                        <a
                            class="c-check-input__link"
                            :href="createUrl(item.value)"
                            @click.prevent="refine(item.value)"
                        >
                            <label
                                class="c-check-input__label"
                                :for="kebab(item.value)"
                            >
                                <input
                                    :id="kebab(item.value)"
                                    type="checkbox"
                                    :checked="item.isRefined"
                                    class="c-check-input__input"
                                />

                                <span class="c-check-input__box" />
                                {{ convertLabel(item.label) }} ({{
                                    item.count
                                }})

                                <svg-component
                                    v-if="filterDepth > 1"
                                    name="chevron-down"
                                    class="c-chevron u-ml-16"
                                    :class="{
                                        'c-chevron--rotated': item.isRefined,
                                    }"
                                />
                            </label>
                        </a>
                    </div>
                </template>
            </check-input>

            <!-- eslint-disable-next-line  -->
      <hierarchical-menu-list
                v-if="item.data"
                :items="item.data"
                :refine="props.refine"
                :create-url="props.createUrl"
                :convert-label="convertLabel"
            />
        </li>
    </ul>
</template>

<script setup>
import { kebab } from "../../filters";

const props = defineProps({
    createUrl: {
        type: Function,
        required: true,
    },
    refine: {
        type: Function,
        required: true,
    },
    items: {
        type: Array,
        required: true,
    },
    convertLabel: {
        type: Function,
        required: true,
    },
    isRootLevel: {
        type: Boolean,
        default: false,
    },
    filterDepth: {
        type: Number,
        default: 1,
    },
});
</script>
