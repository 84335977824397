<template>
    <div class="teststring">
        {{ message }}
    </div>
</template>

<script setup>
import { ref } from "vue";

const message = ref("Hello, World!");
</script>
